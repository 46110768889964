import { FC, ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useFormAction } from '../../contexts/FormActionContext';
import { FormRendererType, useFormRendererInfo } from '../../contexts/FormRendererContext';
import { FormRendererMode } from '../../contexts/FormRendererDesignContextTypes';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import { dataAttributeProps } from '../../utils/ComponentUtils';
import RiskUtils, { RiskRating } from '../../utils/RiskUtils';
import { Option } from '../Option';
import { defaultTitleStyle, useTextStyle } from './action-types/ActionEditorBase';
import { Heading, HeadingSize } from '../shared/text/Heading';
import ReactMarkdown from 'react-markdown';
import MarkdownRenderers from '../../utils/MarkdownRenderers';
import { useWarnOutboundLink } from '../../hooks/useWarnOutboundLink';
import { ClientFormStatus } from '../../models/ClientFormStatus';
import ActionIndicators from './action-types/ActionIndicators';
import { interpolateActionData } from '../../utils/interpolation/ActionDataInterpolator';

type ActionPreviewProps = {
  question: string;
  titleStyle?: Option<string, string>;
  description?: string;
  answer?: string | string[] | ReactElement | ReactElement[];
  riskLevel?: number;
};

const ActionPreview: FC<ActionPreviewProps> = (props) => {
  const { question, description, answer, riskLevel, titleStyle = defaultTitleStyle } = props;
  const { renderMinimal, visibleActionsList, rootContext, clientFormId, placeholders, type } = useFormRendererInfo();
  const { currentAction, currentSection } = useFormAction();
  const externalLinkWarner = useWarnOutboundLink();

  const { formId } = useParams<{ formId: string }>();
  const location = useLocation();
  const { t } = useTranslation(['common', 'risk']);
  const client = useRecoilValue(currentClientAtom);
  const navigate = useNavigate();

  const readOnly = useMemo(
    () => !visibleActionsList.includes(currentAction.id) && !rootContext?.visibleActionsList.includes(currentAction.id),
    [currentAction.id, rootContext?.visibleActionsList, visibleActionsList],
  );

  const answers = useMemo(() => (Array.isArray(answer) ? answer : [answer]).filter((x) => x), [answer]);

  const showRisk = useMemo(() => riskLevel !== undefined && riskLevel > RiskRating.NO_RISK, [riskLevel]);
  const riskText = useMemo(() => riskLevel !== undefined && RiskUtils.textFor(t, riskLevel), [riskLevel, t]);
  const riskBgColor = useMemo(() => riskLevel !== undefined && RiskUtils.bgColorFor(riskLevel), [riskLevel]);
  const selectedTitleStyle = useTextStyle(titleStyle.id);

  const canClickThrough = useMemo(
    () =>
      !readOnly &&
      (rootContext?.clientFormId ?? clientFormId) &&
      currentSection.id &&
      currentAction.id &&
      !rootContext?.clientForm?.originId &&
      rootContext?.clientForm?.status !== ClientFormStatus.Completed &&
      type === FormRendererType.Platform,
    [
      readOnly,
      rootContext?.clientFormId,
      rootContext?.clientForm?.originId,
      rootContext?.clientForm?.status,
      clientFormId,
      currentSection.id,
      currentAction.id,
      type,
    ],
  );

  const editQuestion = useCallback(() => {
    if (!canClickThrough) {
      return;
    }

    if (formId === (rootContext?.clientFormId ?? clientFormId) && !location.pathname.includes('/preview')) {
      const newSearch = new URLSearchParams(location.search);
      newSearch.set('highlight', `${rootContext?.clientFormId ?? clientFormId},${currentSection.id},${currentAction.id}`);
      newSearch.toString();
      navigate({
        search: newSearch.toString(),
      });
      rootContext?.changeMode(FormRendererMode.EditView, currentSection.id);
    } else {
      navigate(
        `/clients/${client?.id}/forms/${rootContext?.clientFormId ?? clientFormId}?edit&highlight=${rootContext?.clientFormId ?? clientFormId},${
          currentSection.id
        },${currentAction.id}`,
      );
    }
  }, [
    canClickThrough,
    formId,
    rootContext,
    clientFormId,
    location.pathname,
    location.search,
    currentSection.id,
    currentAction.id,
    navigate,
    client?.id,
  ]);

  const titleDescription = useMemo(() => {
    return (
      <>
        <div className="font-medium" data-cy="question">
          {showRisk && <div data-cy="risk-dot" className={`-ml-3 mt-2 inline-block h-2 w-2 rounded-full ${riskBgColor}`} title={riskText || ''} />}{' '}
          <span onClick={editQuestion} className={canClickThrough ? 'cursor-pointer hover:underline' : ''}>
            <Heading size={HeadingSize.CUSTOM} className={selectedTitleStyle.value}>
              {currentAction.number} {interpolateActionData(question || '', placeholders)}
            </Heading>
          </span>
        </div>
        <div className="pt-1" data-cy="description">
          <ReactMarkdown components={MarkdownRenderers(placeholders, { a: { onClick: externalLinkWarner.onClick } })}>
            {description || ''}
          </ReactMarkdown>
        </div>
      </>
    );
  }, [
    canClickThrough,
    currentAction,
    description,
    editQuestion,
    externalLinkWarner.onClick,
    placeholders,
    question,
    riskBgColor,
    riskText,
    selectedTitleStyle.value,
    showRisk,
  ]);

  if (renderMinimal) {
    return (
      <>
        {/* Doing it this way to stop headings becoming detatched from the first answer */}
        <div className="max-w-sm">
          <div className="font-medium text-black">{interpolateActionData(question || '', placeholders)}</div>
          <div className={answers.length === 1 ? 'mb-4' : ''}>{answers[0]}</div>
        </div>
        {answers.slice(1).map((a, i) => (
          <div key={i} className={`${i === answers.length - 2 ? 'mb-4' : ''} max-w-sm`}>
            {a}
          </div>
        ))}
      </>
    );
  }

  return (
    <div className={`flex flex-col px-1`} {...dataAttributeProps(props)}>
      {externalLinkWarner.modal}
      {currentAction.noninteractive && titleDescription}
      {!currentAction.noninteractive && (
        <>
          <div className="flex items-start justify-between">
            <div className="flex-grow text-black">{titleDescription}</div>{' '}
            <div className="flex gap-1 text-right" data-cy="risk-label">
              <div className="flex items-center gap-1">
                <ActionIndicators />
              </div>
            </div>
          </div>
          <div className="text-color-2 relative" data-cy="answers">
            {answers.map((a, i) => (
              <div
                data-cy={`answer-${i}`}
                style={{
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                }}
                key={i}
              >
                {a}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ActionPreview;
